


import { Component, Vue } from 'vue-property-decorator';
import newsList from '@/_modules/news/components/news-list/news-list.vue';
import SearchBar from '@/_components/search-bar/search-bar.vue';
import PageTitle from '@/_components/page-title/page-title.vue';

@Component({
  components: {
    SearchBar,
    PageTitle,
    newsList,
  },
})
export default class News extends Vue {

  public get eventId(): number {
    return this.$route.params.eventId ? parseInt(this.$route.params.eventId, 10) : null;
  }

  public get newsIssueId(): number {
    return this.$route.params.newsIssueId ? parseInt(this.$route.params.newsIssueId, 10) : null;
  }

  public get isNewsIssueColumnVisible(): boolean {
    return !!this.newsIssueId;
  }

  private onListScrollTopUpdated(data: number): void {
    try {
      const columnFirstScroll: Vue = this.$refs.columnFirstScroll as Vue;
      columnFirstScroll.$el.scrollTop = data;
    } catch (e) {
      /* ignore */
    }
  }

}
